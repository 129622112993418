import React from "react"
import NavbarThree from "../components/App/NavbarThree"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"


const Home = ({location}) => {
  return (
    <Layout path={location.pathname}>
      <NavbarThree />
      <Footer />
    </Layout>
  )
}

export default Home;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;